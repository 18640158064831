<template>
  <TitleContentPanel title="单层最大闲置面积Top5" height="100%">
    <div class="left-1-wrapper">
      <ul v-for="(item, index) in dataList" :key="index" @click="handleItemClick(item)">
        <li>
          <div class="index-label">
            <span class="index" :class="{ top3: index < 3 }">{{ index + 1 }}</span>
            <div class="label">{{ item.name }}</div>
          </div>
          <div class="count">{{ (item.maxFloorLeaveArea || 0).toFixed(2) }} ㎡</div>
        </li>
      </ul>
    </div>
  </TitleContentPanel>

  <Modal :isShow="showDetail">
    <div class="item-detail">
      <BuildingDetail @onClose="showDetail = false" />
    </div>
  </Modal>
</template>

<script>
import TitleContentPanel from '@/components/TitleContentPanel'
import Modal from '@/components/Modal'
import BuildingDetail from '@/components/ListView/BuildingDetail'
import { aggregate } from '@/api/aggregate'
import { ref, computed, watch } from 'vue'
import { useStore } from 'vuex'
import { SET_CURRENT_BUILDING_ID } from '@/store/mutation-types'
export default {
  name: 'Left1',
  components: { TitleContentPanel, BuildingDetail, Modal },
  setup: () => {
    const store = useStore()
    const dataList = ref([])
    const showDetail = ref(false)
    const currentStreetId = computed(() => store.state.common.currentStreetId)
    const currentBuildingTag = computed(() => store.state.buildingResources.currentBuildingTag)
    const options = {}

    const fetchData = (options = {}) => {
      const { streetId, buildingType } = options

      const params = {
        cmdList: [
          { $match: {} },
          { $match: { 'data.buildingTypeLabel': { $in: ['商务楼宇'] } } },
          {
            $project: {
              name: '$data.name',
              maxFloorLeaveArea: { $toDouble: '$data.maxFloorLeaveArea' }
            }
          },
          { $sort: { maxFloorLeaveArea: -1 } },
          { $limit: 5 }
        ],
        templateName: 'building_information'
      }

      if (streetId) {
        params.cmdList[0].$match['data.street'] = `NumberLong(${streetId})`
      }

      if (buildingType) {
        params.cmdList[0].$match['data.buildingType'] = buildingType
      }

      aggregate(params).then(res => {
        dataList.value = res.data
      })
    }

    fetchData()

    watch(currentStreetId, id => {
      options.streetId = id
      fetchData(options)
    })

    watch(currentBuildingTag, tag => {
      if (tag) {
        options.buildingType = { $in: [`${tag}`] }
      } else {
        delete options.buildingType
      }
      fetchData(options)
    })

    const handleItemClick = item => {
      console.log(item)
      store.commit(SET_CURRENT_BUILDING_ID, item.id)
      showDetail.value = true
    }
    return { dataList, showDetail, handleItemClick }
  }
}
</script>

<style lang="less" scoped>
.left-1-wrapper {
  height: 100%;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #fff;
  padding: 0 20px;

  & > ul {
    width: 100%;
    li {
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 45px;

      .index-label {
        display: flex;
        align-items: center;
        height: 45px;
        flex: 1;
        width: 1px;

        .top3 {
          background-color: #1e75ff !important;
        }

        .index {
          display: inline-block;
          width: 22px;
          height: 22px;
          line-height: 22px;
          border-radius: 50%;
          background-color: #999;
          text-align: center;
          margin-right: 10px;
        }

        & > .label {
          flex: 1;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }
    }
  }
}

.item-detail {
  width: 1180px;
  height: 950px;
  background-color: #0b0f1a;
  padding: 20px;
  overflow: hidden;
}
</style>
