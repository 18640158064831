<template>
  <div class="left-part-wrapper">
    <div class="left-1 box">
      <Left1 />
    </div>
    <div class="left-2 box">
      <Left2 />
    </div>
    <div class="left-3 box">
      <Left3 />
    </div>
  </div>
</template>

<script>
import Left1 from './components/Left1'
import Left2 from './components/Left2'
import Left3 from './components/Left3'

export default {
  name: 'LeftPart',
  components: { Left1, Left2, Left3 },
  props: {
    menu: {
      type: Object,
      default: () => ({})
    }
  },
  setup: props => {
    return {}
  }
}
</script>

<style lang="less" scoped>
@bgc: #1c1c24;
.left-part-wrapper {
  height: 100%;

  .box {
    border-radius: 20px;
    background-color: @bgc;
    overflow: hidden;
  }

  .left-1,
  .left-2 {
    height: 304px;
    margin-bottom: 20px;
  }
  .left-3 {
    height: calc(100% - 304px - 304px - 40px);
  }
}
</style>
