<template>
  <div class="left-part-wrapper">
    <div class="street-list">
      <BuildingList :sortData="sortData" :rightDataList="rightDataList" />
    </div>
  </div>
</template>

<script>
import BuildingList from '@/components/ListView/BuildingList'
// import StreetList from './components/StreetList'
// import { ref } from 'vue'
const sortData = [
  {
    label: '楼宇名称',
    sort: -1,
    key: 'name',
    setName: 'data'
  },
  {
    label: '闲置面积',
    sort: -1,
    key: 'leaveArea',
    setName: 'data'
  },
  {
    label: '空置率',
    sort: -1,
    key: 'leaveAreaRate',
    setName: 'data'
  }
]

export default {
  name: 'LeftPart',
  components: { BuildingList },

  setup: props => {
    const rightDataList = [
      {
        label: '闲置面积',
        valueKey1: 'data',
        valueKey2: 'leaveArea',
        unit: '㎡',
        format: false
      },
      {
        label: '空置率',
        valueKey1: 'stats',
        valueKey2: 'leaveAreaRate',
        unit: '%',
        format: false
      }
    ]
    // const currentList = ref('StreetList')
    return { sortData, rightDataList }
  }
}
</script>

<style lang="less" scoped>
.left-part-wrapper {
  height: 100%;

  .pie-chart {
    height: 100%;
  }

  .street-list {
    height: 100%;
  }
}
</style>
