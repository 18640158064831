<template>
  <div class="building-resources-wrapper">
    <LCRLayout>
      <template #left>
        <LeftPart />
      </template>

      <template #center>
        <CenterPart />
      </template>

      <template #right>
        <RightPart />
      </template>
    </LCRLayout>
  </div>
</template>

<script>
import LCRLayout from '@/layout/LCRLayout'

import LeftPart from './LeftPart'
import CenterPart from './CenterPart'
import RightPart from './RightPart'
import useLoading from '@/hooks/useLoading'
export default {
  name: 'BuildingResources',
  components: { LCRLayout, LeftPart, CenterPart, RightPart },
  setup: () => {
    const { hidLoading } = useLoading()
    hidLoading()
    return {}
  }
}
</script>

<style lang="less" scoped>
.building-resources-wrapper {
  height: 100%;
}
</style>
