<template>
  <TitleContentPanel title="资源概况" height="100%">
    <template #append>
      <span @click="handleDesc">概况简介</span>
    </template>

    <div class="left-1-wrapper">
      <div class="total-count">
        <span class="text">租售房源总数</span>
        <CountTo class="count" :startVal="0" :endVal="dataObj.roomCount" :duration="1000" />
        <span class="text">套</span>
      </div>

      <div class="key1-key2-key3">
        <LabelCount class="label-count" label="建筑总面积（㎡）" :count="dataObj.totalArea" />
        <LabelCount class="label-count" label="空置总面积（㎡）" :count="dataObj.freeArea" />
        <LabelCount
          class="label-count"
          :decimals="2"
          label="总空置率（%）"
          :count="dataObj.freeAreaPercent"
        />
      </div>
    </div>
  </TitleContentPanel>

  <Modal :isShow="dataObj.showDesc">
    <div class="desc-title">
      <div class="title-close">
        <span class="title">市南区楼宇资源基本情况</span>
        <div class="net-admin-btn">
          <Icon class="close" name="close" @click="dataObj.showDesc = false" />
        </div>
      </div>

      <div class="desc-content">
        <div class="part">
          {{ part }}
        </div>
      </div>
    </div>
  </Modal>
</template>

<script>
import LabelCount from '@/components/ListView/LabelCount'
import TitleContentPanel from '@/components/TitleContentPanel'
import { CountTo } from 'vue-count-to2'
import { aggregateList } from '@/api/aggregate'
import { ref, computed, watch, reactive } from 'vue'
import { useStore } from 'vuex'
import Modal from '@/components/Modal'
import Icon from '@/components/IconSvg'
import { getIndustryListLevel0 } from '@/api/list'

export default {
  name: 'Left1',
  components: { LabelCount, TitleContentPanel, CountTo, Modal, Icon },
  setup: () => {
    const store = useStore()
    const currentStreetId = computed(() => store.state.common.currentStreetId)
    const currentBuildingTag = computed(() => store.state.buildingResources.currentBuildingTag)
    const options = {}
    const part = ref('')

    const dataObj = reactive({
      roomCount: 0, // 总房源数
      totalArea: 0, // 总面积
      freeArea: 0, // 空置面积
      freeAreaPercent: 0, // 空置率
      showDesc: false
    })

    const fetchData = (options = {}) => {
      const { streetId, buildingType } = options

      const params = {
        roomCount: {
          cmdList: [
            { $match: {} },
            { $match: { 'data.buildingTypeLabel': { $in: ['商务楼宇'] } } },
            { $group: { _id: '', sum: { $sum: { $toDouble: '$stats.rentSaleCounts' } } } }
          ],
          templateName: 'building_information'
        },
        totalArea: {
          cmdList: [
            { $match: {} },
            { $match: { 'data.buildingTypeLabel': { $in: ['商务楼宇'] } } },
            { $group: { _id: '', sum: { $sum: { $toDouble: '$data.totalArea' } } } },
            { $project: { _id: 0, sum: 1 } }
          ],
          templateName: 'building_information'
        },
        freeArea: {
          cmdList: [
            { $match: {} },
            { $match: { 'data.buildingTypeLabel': { $in: ['商务楼宇'] } } },
            { $group: { _id: '', sum: { $sum: { $toDouble: '$data.leaveArea' } } } },
            { $project: { _id: 0, sum: 1 } }
          ],
          templateName: 'building_information'
        }
      }

      if (streetId) {
        params.roomCount.cmdList[0].$match['data.street'] = `NumberLong(${streetId})`
        params.totalArea.cmdList[0].$match['data.street'] = `NumberLong(${streetId})`
        params.freeArea.cmdList[0].$match['data.street'] = `NumberLong(${streetId})`
      }

      if (buildingType) {
        params.roomCount.cmdList[0].$match['data.buildingType'] = buildingType
        params.totalArea.cmdList[0].$match['data.buildingType'] = buildingType
        params.freeArea.cmdList[0].$match['data.buildingType'] = buildingType
      }

      aggregateList(params).then(res => {
        dataObj.roomCount = res.data.roomCount[0]?.sum || 0
        dataObj.totalArea = res.data.totalArea[0]?.sum || 0
        dataObj.freeArea = res.data.freeArea[0]?.sum || 0
        dataObj.freeAreaPercent =
          Number(((dataObj.freeArea / dataObj.totalArea) * 100).toFixed(2)) || 0
      })
    }

    const getInfo = industryMap => {
      const params = {
        buildingCounts: {
          cmdList: [
            { $match: { isDeleted: -1 } },
            { $match: { 'data.name': { $exists: 1 } } },
            { $match: { 'data.buildingTypeLabel': { $in: ['商务楼宇'] } } },
            {
              $group: {
                _id: 'null',
                counts: { $sum: 1 },
                totalArea: { $sum: { $toDouble: '$data.totalArea' } },
                leaveArea: { $sum: { $toDouble: '$data.leaveArea' } }
              }
            }
          ],
          templateName: 'building_information'
        },
        rentCounts: {
          cmdList: [
            { $match: {} },
            { $match: { 'data.buildingTypeLabel': { $in: ['商务楼宇'] } } },
            { $group: { _id: '', count: { $sum: { $toDouble: '$stats.rentSaleCounts' } } } }
          ],
          templateName: 'building_information'
        },
        rentInfo: {
          cmdList: [
            { $match: {} },
            { $match: { 'data.buildingTypeLabel': { $in: ['商务楼宇'] } } },
            { $project: { name: '$data.name', count: { $toDouble: '$stats.rentSaleCounts' } } },
            { $sort: { count: -1 } },
            { $limit: 3 }
          ],
          templateName: 'building_information'
          // cmdList: [
          //   {
          //     $group: {
          //       _id: '$data.registerBuildingLabel',
          //       count: { $sum: 1 }
          //     }
          //   },
          //   { $match: { _id: { $ne: 'null' } } },
          //   { $sort: { count: -1 } },
          //   { $limit: 3 }
          // ],
          // templateName: 'renting_information'
        },
        buildingInfo: {
          cmdList: [
            { $match: { 'data.buildingTypeLabel': { $in: ['商务楼宇'] } } },
            { $project: { name: '$data.name', leaveArea: { $toDouble: '$data.leaveArea' } } },
            { $sort: { leaveArea: -1 } },
            { $limit: 3 }
          ],
          templateName: 'building_information'
        }
      }

      aggregateList(params).then(res => {
        console.log(res.data, industryMap)
        const { buildingCounts, rentCounts, rentInfo, buildingInfo } = res.data
        const key1 = buildingCounts[0]?.counts || 0
        const key2 = buildingCounts[0]?.totalArea || 0
        const key3 = buildingCounts[0]?.leaveArea || 0
        const key4 = rentCounts[0]?.count || 0
        const key5 = rentInfo.map(d => d.name).join('、')
        const key6 = rentInfo.map(d => `${d.count}套`).join('、')
        const key7 = buildingInfo.map(d => d.name).join('、')
        const key8 = buildingInfo.map(d => `${d.leaveArea}平米`).join('、')
        part.value = `全区共有${key1}栋在库楼宇，总建筑面积${key2}平米，总空置面积${key3}平米。在租（售）房源${key4}套。其中在租（售）房源${key5}排名前三，分别为${key6}。空置面积${key7}排名前三，分别为${key8}。`
      })
    }

    fetchData()

    getIndustryListLevel0().then(res => {
      const list = res.data
      const map = {}
      list.forEach(d => {
        map[d.code] = d.name
      })
      getInfo(map)
    })

    watch(currentStreetId, streetId => {
      console.log(streetId)
      options.streetId = streetId
      fetchData(options)
    })

    watch(currentBuildingTag, tag => {
      if (tag) {
        options.buildingType = { $in: [`${tag}`] }
      } else {
        delete options.buildingType
      }
      fetchData(options)
    })

    const handleDesc = () => {
      dataObj.showDesc = true
    }
    return { dataObj, handleDesc, part }
  }
}
</script>

<style lang="less" scoped>
@bgcColor: #1c1c24;
@textColor: fade(#fff, 85%);
@titleBgc: #1b1c1c;

.left-1-wrapper {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  color: #fff;

  .total-count {
    .text {
      font-size: 16px;
      margin: 0 10px;
    }

    .count {
      font-size: 30px;
    }
  }

  .key1-key2-key3,
  .key4-key5-key6 {
    width: 100%;
    display: flex;
    justify-content: space-around;
  }
}

.desc-title {
  width: 900px;
  height: 550px;
  background-color: #0b0f1a;
  // padding: 20px;
  overflow: hidden;
  padding: 0 30px 30px;

  .title-close {
    height: 75px;
    color: @textColor;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    font-size: 24px;
    // padding-left: 20px;

    .net-admin-btn {
      display: flex;
      align-items: center;

      .close {
        position: absolute;
        top: 50%;
        right: 0px;
        transform: translateY(-50%);
        width: 20px;
        height: 20px;
        color: @textColor;
        cursor: pointer;
      }
    }
  }

  .desc-content {
    background-color: #161e31;
    border-radius: 6px;
    height: calc(100% - 75px);
    padding: 45px 30px;

    .part {
      color: #fff;
      font-size: 18px;
      line-height: 2;
      text-indent: 2em;
      letter-spacing: 2px;
    }
  }
}
</style>
