<template>
  <div class="center-part-wrapper">
    <!-- mapStyle="amap://styles/darkblue" -->
    <!-- mapStyle="amap://styles/3c5b5047c37621e5b97d427e94ccc67f" -->
    <AMap
      ref="amapRef"
      :aMapKey="AMAP_KEY"
      mapStyle="amap://styles/3c5b5047c37621e5b97d427e94ccc67f"
      :zoom="13"
      :showLabel="false"
      :pitch="0"
      viewMode="3D"
      :plugins="['GeoJSON', 'IndexCluster']"
      :center="[120.357907, 36.066541]"
      :isReady="isReady"
      @mapLoaded="handleMapLoaded"
    >
    </AMap>

    <div class="menu-btn">
      <div class="menu">
        <!-- <SubMenu :dataList="subMenuList" :defalutIndex="0" @onChange="handleMenuChange" /> -->
        <SubMenuWithSelect />
      </div>
      <div class="btn">
        <CustomRadioGroup
          :dataList="btnDataList"
          title="柱状数据维度"
          @onChange="handleBtnChange"
        />
      </div>

      <MapLegend :max="max" :label="btnName" :more="more" />
    </div>
  </div>
</template>

<script>
import AMap from '@/components/AMap'
import { AMAP_KEY } from '@/config/commonConfig'
import { ref, onBeforeUnmount, computed } from 'vue'
import { getStreetPolygon } from '@/api/map'
import useBuilding from './hooks/useBuilding'
import CustomRadioGroup from '@/components/CustomRadioGroup'
import SubMenuWithSelect from './SubMenuWithSelect'
import { useLoca } from '@/hooks'
import { useStore } from 'vuex'
import { SET_CURRENT_DATA_DIMENSIONAL } from '@/store/mutation-types'
import MapLegend from '@/components/MapLegend'

const btnDataList = [
  {
    label: 'leaveArea',
    name: '闲置面积 ㎡'
  },
  {
    label: 'leaveAreaRate',
    name: '空置率 %'
  }
]

export default {
  name: 'CenterPart',
  components: { AMap, CustomRadioGroup, SubMenuWithSelect, MapLegend },
  setup: () => {
    let locaObj
    const more = ref(true)
    const store = useStore()
    const isReady = ref(false)
    const btnLabel = ref(btnDataList[0].label)
    const btnName = ref(btnDataList[0].name)
    const mapBuildingGeojson = computed(() => store.state.common.mapBuildingGeojson)
    const partMap = {}
    const max = ref(mapBuildingGeojson.value.maxArea)

    const handleMapLoaded = ({ map, AMap }) => {
      const getMapBuildingGeojson = store.dispatch('getMapBuildingGeojson')

      Promise.all([getStreetPolygon(), getMapBuildingGeojson]).then(
        ([streetData, buildingData]) => {
          const { loca } = useLoca(map)
          locaObj = loca

          partMap.building = useBuilding(map, AMap, loca, {
            streetData,
            buildingData
          })

          partMap.building.show()

          isReady.value = true
        }
      )
    }

    const handleBtnChange = label => {
      btnLabel.value = label
      btnName.value = btnDataList.find(d => d.label === label).name
      if (label === 'leaveArea') {
        max.value = mapBuildingGeojson.value.maxArea
        more.value = true
      } else {
        max.value = mapBuildingGeojson.value.maxAreaRate
        more.value = false
      }
      store.commit(SET_CURRENT_DATA_DIMENSIONAL, label)
    }

    onBeforeUnmount(() => {
      // 页面离开时需要销毁，否则绘制有问题
      locaObj && locaObj.animate.stop()
      locaObj && locaObj.destroy()

      Object.keys(partMap).forEach(partName => {
        const layer = partMap[partName]
        layer.unwatch && layer.unwatch()
        layer.unwatch2 && layer.unwatch2()
      })
    })

    return {
      AMAP_KEY,
      isReady,
      handleMapLoaded,
      btnDataList,
      handleBtnChange,
      mapBuildingGeojson,
      max,
      btnName,
      more
    }
  }
}
</script>

<style lang="less" scoped>
.center-part-wrapper {
  position: relative;
  width: 100%;
  height: 100%;
  border-radius: 20px;
  overflow: hidden;

  .menu-btn {
    width: 100%;
    .menu {
      // height: 70px;
      position: absolute;
      top: 20px;
      left: 50%;
      transform: translateX(-50%);
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: rgba(0, 0, 0, 0.5);
      background-color: #0b0f1a;
      border-radius: 4px;
    }

    .btn {
      position: absolute;
      right: 20px;
      bottom: 20px;
    }
  }
}
</style>
